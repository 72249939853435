<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
							style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-8">
															<div class="vc_column-inner">
																<div class="wpb_wrapper"></div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p>
																				<router-link :to="{ name: 'enhanced-focus-a45' }">
																						<em>Up
																						Next: </em> Staying Focused <img
																						class="vc_single_image-img attachment-full"
																						src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
																						alt="" width="64"
																						height="37" />
																				</router-link>
																						</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1636691391154"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-3">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>Enhanced focus</h2>
																			<h3>Focused breathing</h3>
																			<p>One of your best sources of energy and
																				focus is your breath. These breathing
																				exercises are best done before starting
																				a stretch of focused work.</p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-9">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_right   rounded">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img class="vc_single_image-img "
																					src="../../assets/images/airbnb/Airbnb-Enhanced-Focus-Focused-Breathing.jpg"
																					width="700" height="360"
																					alt="Airbnb---Enhanced-Focus---Focused-Breathing"
																					title="Airbnb---Enhanced-Focus---Focused-Breathing" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner "
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div
															class="center wpb_column vc_column_container vc_col-sm-4 vc_hidden-sm vc_hidden-xs">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p><a class="image-popup headerVideoLink popup-modal" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1"><br />
																					<img src="../../assets/images/airbnb/Airbnb-Productivity-Focused-Breathing-Grounding-Breath.jpg"
																						alt="Deep Breath" /><br />
																					<span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15"> Deep
																						Breath</span><br />
																				</a></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="center wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p><a class="image-popup headerVideoLink popup-modal" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2"><br />
																					<img src="../../assets/images/airbnb/Airbnb-Productivity-Focused-Breathing-Belly-Breath.jpg"
																						alt="Belly Breath" /><br />
																					<span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15">
																						Belly Breath</span><br />
																				</a></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="center wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p><a class="image-popup headerVideoLink popup-modal" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3"><br />
																					<img src="../../assets/images/airbnb/Airbnb-Enhanced-Focus-Breathing-Circle-Breath.jpg"
																						alt="Circle Breath" /><br />
																					<span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15">
																						Circle Breath</span><br />
																				</a></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
							<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
							<div class=" section_inner clearfix">
								<div class="section_inner_margin clearfix">
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2>Discover more productivity tips</h2>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																	<div class="wpb_wrapper">
																		<router-link :to="{ name: 'increase-your-energy-a45' }">
																			<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="537"
																						src="../../assets/images/airbnb/Airbnb-Working-From-Office-Everyday-stretches.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Working-From-Office---Everyday-stretches">
																			</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'increase-your-energy-a45' }">
																			<h4>Increase your energy</h4>
																			<p>How to manage your energy rather than
																				your time.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'the-pomodoro-technique-a45' }">
																			
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="364"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Pomodoro.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Pomodoro"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'the-pomodoro-technique-a45' }">
																			<h4>Pomodoro Technique</h4>
																			<p>Manage your time with the Pomodoro
																				Technique.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'sensory-overload-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Sensory-Overload.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Sensory-Overload"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'sensory-overload-a45' }">
																			<h4>Sensory overload</h4>
																			<p>Check out these hacks to avoid headaches,
																				eye and ear fatigue and more.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'productivity-hacks-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="2560" height="905"
																						src="../../assets/images/airbnb/Replacement-for-Productivity-Hacks-Structure-Your-Day2-scaled.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Replacement for Productivity Hacks - Structure Your Day2"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'productivity-hacks-a45' }">
																			<h4>Productivity hacks</h4>
																			<p>Productivity hacks to help you crush your
																				work day.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'time-management-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="501"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Time-Management.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity--Time-Management"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'time-management-a45' }">
																			<h4>Time management</h4>
																			<p>Manage your time between work and
																				downtime.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'enhanced-focus-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="482"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Enhanced-Focus.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Enhanced-Focus"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'enhanced-focus-a45' }">
																			<h4>Enhanced focus</h4>
																			<p>Get focused and get productive with these
																				focus enhancing tips.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Video Modal start Here -->
  


	<div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed"
												src="https://player.vimeo.com/video/327606957?h=60c20a6401&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>  
						
						<div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal1">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed1"
												src="https://player.vimeo.com/video/327602996?h=80fac54136&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>  
					
						<div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal2">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed2"
												src="https://player.vimeo.com/video/327604190?h=be364e2193&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div> 
    <!-- Video Modal End Here -->
					



					</div>
				</div>
				

			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'focused-breathing-a45',
		methods: {
			hideModal() {
      // this.$emit('hide');
      document.getElementById("closed").src += "";
    },
    hideModal1() {
      // this.$emit('hide');
      document.getElementById("closed1").src += "";
    },
    hideModal2() {
      // this.$emit('hide');
      document.getElementById("closed2").src += "";
    },
		}
	}
</script>